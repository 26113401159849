import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@everfund/ui';

export type EmptyBarrierType = {
  icon?: IconDefinition | JSX.Element;
  title?: string;
  description?: string | JSX.Element;
  className?: string;
};

const EmptyBarrier = ({ icon, title, description, className }: EmptyBarrierType) => {
  return (
    <div className={cn('space-y-4 px-5 py-6 text-center', className)}>
      {typeof icon === 'object' && 'iconName' in icon ? (
        <FontAwesomeIcon icon={icon} className="mx-auto !h-16 !w-auto text-gray-400" />
      ) : icon ? (
        icon
      ) : null}

      <h3 className="text-xl font-medium text-primary">{title}</h3>
      {description && <p className="mx-auto max-w-3xl text-base text-secondary">{description}</p>}
    </div>
  );
};

export default EmptyBarrier;
