'use client';

import useManyOrganizations from '@/requests/queries/manyOrganizations';
import { Organizations } from '@/services/organizations/many';
import { createContext } from 'react';
import { useMemo } from 'react';

export type Context = {
  organizations?: Organizations;
  isFetching: boolean;
};

export const OrganizationsContext = createContext<Context>({ organizations: undefined, isFetching: false });

type ProviderProps = {
  children: React.ReactNode;
  id?: string | null;
};

export function OrganizationsProvider({ children }: ProviderProps) {
  const { data: organizations, isFetching } = useManyOrganizations({ index: 1, size: 100 });

  const contextValue = useMemo(
    () => ({
      organizations: organizations,
      isFetching,
    }),
    [organizations, isFetching]
  );

  return <OrganizationsContext.Provider value={contextValue}>{children}</OrganizationsContext.Provider>;
}
