import { EVERFUND_MARKETING_DOMAIN } from '@/context/constants';
import { faGithub, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';

const Footer = () => {
  const t = useTranslations('layouts.components.Footer');

  const navigation = {
    main: [
      {
        name: t('developerDocs'),
        href: `https://docs.${EVERFUND_MARKETING_DOMAIN}/`,
      },
      {
        name: t('cookiePolicy'),
        href: `https://www.iubenda.com/privacy-policy/82630393/cookie-policy`,
      },
      {
        name: t('privacyPolicy'),
        href: `https://www.iubenda.com/privacy-policy/82630393/full-legal`,
      },
      {
        name: t('termsAndConditions'),
        href: `https://www.iubenda.com/terms-and-conditions/82630393`,
      },
    ],
    social: [
      {
        name: t('instagram'),
        href: 'https://www.instagram.com/_everfund',
        Icon: (props: Omit<FontAwesomeIconProps, 'icon'>) => <FontAwesomeIcon icon={faInstagram} {...props} />,
      },
      {
        name: t('twitter'),
        href: 'https://x.com/_everfund',
        Icon: (props: Omit<FontAwesomeIconProps, 'icon'>) => <FontAwesomeIcon icon={faXTwitter} {...props} />,
      },
      {
        name: t('facebook'),
        href: 'https://www.linkedin.com/company/everfundcom',
        Icon: (props: Omit<FontAwesomeIconProps, 'icon'>) => <FontAwesomeIcon icon={faLinkedin} {...props} />,
      },
      {
        name: t('github'),
        href: 'https://github.com/everfund',
        Icon: (props: Omit<FontAwesomeIconProps, 'icon'>) => <FontAwesomeIcon {...props} icon={faGithub} />,
      },
    ],
  };

  return (
    <footer className="w-full border-t px-4 py-12 dark:border-nord-1 md:px-12 md:pb-20">
      <div className="mx-auto flex max-w-8xl flex-col items-start gap-4 overflow-hidden md:flex-row md:items-center md:justify-between">
        <nav className="flex flex-col flex-wrap justify-center gap-2 md:flex-row md:gap-5" aria-label="Footer">
          {navigation.main.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-base text-secondary transition duration-300 ease-in-out hover:text-primary"
            >
              {item.name}
            </a>
          ))}
        </nav>
        <div className="flex justify-center space-x-3 md:space-x-6">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-secondary transition hover:text-primary">
              <span className="sr-only">{item.name}</span>
              <item.Icon className="size-6" aria-hidden="true" />
            </a>
          ))}
        </div>
      </div>
      <div className="mx-auto mt-8 flex max-w-8xl flex-col items-end gap-2 overflow-hidden text-start md:flex-row md:items-center md:justify-between">
        <p className="order-1 text-base text-gray-400 md:order-none ">
          {t('copywrite', { year: new Date().getFullYear() })}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
