import { SetModeResponse } from '@/services/users/setMode';
import MainToast from '@/shared/Toasts/MainToast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

const useSetMode = () => {
  const queryClient = useQueryClient();
  return useMutation<SetModeResponse, unknown, { liveMode: boolean }>({
    mutationFn: async (variables) => {
      return await fetch('/api/users/setMode', {
        method: 'PATCH',
        body: JSON.stringify(variables),
      }).then((res) => res.json());
    },
    mutationKey: ['setMode'],
    onSuccess: async () => {
      await queryClient.invalidateQueries();
    },
    onError: (error) => {
      console.error('Set Mode Error', error);
      toast.custom((to) =>
        MainToast({
          t: to,
          toast,
          type: 'error',
          title: 'Something went wrong :(',
        })
      );
    },
  });
};

export default useSetMode;
