import { Organizations } from '@/services/organizations/many';
import { TablePagination } from '@/shared/Table';
import { TableInput } from '@/types/types';
import { useQuery } from '@tanstack/react-query';

export const ORGANIZATIONS_QUERY_KEY = 'organizations';

const useManyOrganizations = ({ index, size }: TablePagination) => {
  return useQuery<TableInput, unknown, Organizations>({
    queryFn: async () => (await fetch(`/api/organizations?index=${index}&size=${size}`)).json(),
    queryKey: [ORGANIZATIONS_QUERY_KEY, { index, size }],
    keepPreviousData: true,
  });
};

export default useManyOrganizations;
