'use client';

import useIsClient from '@/hooks/useIsClient';
import { Link } from '@/navigation';
import LoadingCell from '@/shared/LoadingCell';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route } from 'next';
import { ReactElement, ReactNode } from 'react';

import { cn } from '@everfund/ui';

import Separator from '../Separator/Separator';

export interface CardProps {
  /**
   * the buttons are displayed in the top right of the card
   * this is useful for when you want to add a button to the card
   */
  buttons?: ReactNode;
  /**
   * React Children that will be rendered inside the card
   */
  children?: ReactNode | ReactNode[];
  /**
   * Footer section area that can be used for buttons or other actions
   */
  footer?: ReactNode;
  /**
   * Hides divider between the bottom of the card set to true, for stacked cards
   */
  hideDivider?: boolean;
  /**
   * Removes the padding from the body of the card
   * Useful for when you want to use a table or other component that has its own padding
   */
  noBodyPadding?: boolean;
  /**
   * The title of the card this can be a string or a react element
   */
  title?: ReactNode;
  /**
   * If you want the title be a link to a page set it as a router.path
   */
  titleLink?: Route;
  /**
   * ClassName for card
   */
  cardClassName?: string | false;
}

const Card = ({
  buttons,
  children,
  footer,
  hideDivider,
  title,
  titleLink,
  noBodyPadding,
  cardClassName,
}: CardProps): ReactElement => {
  const hasCardTitleNav = title || buttons;
  const isClient = useIsClient();

  if (!isClient) {
    return <LoadingCell />;
  }

  return (
    <>
      <div className={cn('relative mb-5 rounded-lg bg-white shadow dark:bg-nord-3', cardClassName)}>
        {hasCardTitleNav && (
          <div className="px-4 pb-4 pt-8 font-title font-medium tracking-wider sm:px-6">
            <div
              className={cn(
                'flex flex-wrap items-center sm:flex-nowrap',
                title && buttons ? 'justify-between' : '',
                !title && buttons ? 'justify-end' : ''
              )}
            >
              {(typeof title === 'string' || titleLink) && (
                <div className="">
                  {titleLink && (
                    <Link href={titleLink} className="flex flex-row items-center text-primary hover:underline">
                      <h3 className="text-base text-primary xl:text-lg">{title}</h3>{' '}
                      <FontAwesomeIcon icon={faChevronRight} className="ml-2 h-4 w-4" />
                    </Link>
                  )}

                  {!titleLink && <h3 className="text-base text-primary xl:text-lg">{title}</h3>}
                </div>
              )}

              {typeof title === 'object' && <>{title}</>}
              {buttons && <div className="flex shrink-0">{buttons}</div>}
            </div>
          </div>
        )}
        {children && !noBodyPadding ? <div className="p-4 sm:px-6">{children}</div> : children}

        {footer && (
          <div className="bg-gray-100 px-5 py-3 font-title font-medium tracking-wider dark:bg-nord-2 sm:rounded-b-lg sm:px-6">
            {footer}
          </div>
        )}
      </div>

      {!hideDivider && (
        <div className="hidden sm:block">
          <Separator padding />
        </div>
      )}
    </>
  );
};

export default Card;
