import { config } from '@/env';
import useSingleWorkspace from '@/requests/queries/singleWorkspace';
import { User } from '@/services/users/single';
import { isTruthy } from '@/utils/shared';
import { UserResource } from '@clerk/types';
import { Crisp } from 'crisp-sdk-web';

function shouldCrispBeUsed(user?: User | UserResource | null) {
  // don't initialise crisp on auth redirect pages and for impersonated users
  return (
    !(
      window?.location?.pathname === '/authentication/email-link' ||
      window?.location?.pathname === '/authentication/oauth'
    ) &&
    // env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT === 'production' &&
    !!config.crispWebsiteId &&
    !!user?.id
  );
}

export function initialiseCrisp({
  user,
  clerkUser,
  workspaceName,
}: {
  user?: User | null;
  clerkUser?: UserResource | null;
  workspaceName?: string;
}) {
  if (shouldCrispBeUsed(user)) {
    Crisp.configure(config.crispWebsiteId!, { safeMode: true });
    if (user?.id || clerkUser?.id) {
      const currentEmailAddress =
        user?.email ??
        clerkUser?.emailAddresses.find(({ id }: { id: string }) => {
          return id === clerkUser.primaryEmailAddressId;
        })?.emailAddress;

      if (currentEmailAddress) {
        Crisp.user.setEmail(currentEmailAddress);
      }
      if (isTruthy(user?.firstName) && isTruthy(user?.lastName)) {
        Crisp.user.setNickname(`${user?.firstName} ${user?.lastName}`);
      }
      if ((user?.id && user?.clerkUserId) || (clerkUser?.id && clerkUser?.externalId)) {
        Crisp.session.setData({
          clerkUserId: user?.clerkUserId ?? clerkUser?.id,
          everfundUserId: user?.id ?? clerkUser?.externalId,
        });
      }

      workspaceName && Crisp.user.setCompany(workspaceName, {});
    }
  }
}

export const ONBOARDING_SEGMENT_ORDERING = ['Signed Up', 'Reached Dashboard', 'Onboarding Complete'];

export function addSegment(segment: string, user?: UserResource | null, ordering?: string[]) {
  if (shouldCrispBeUsed(user)) {
    let array = [segment];

    if (ordering) {
      if (!ordering.includes(segment)) {
        console.error(`${segment} is not a member of the given ordering.`);
      }

      const index = ordering.findIndex((item) => item === segment);
      array = ordering.slice(0, index + 1);
    }

    Crisp.session.setSegments(array, false);
  }
}
