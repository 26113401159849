import useCrisp from '@/hooks/useCrisp';
import { faTriangleExclamation } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';

import { cn } from '@everfund/ui';

import Button from '../Button';

export type ErrorStateFullyCustom = {
  title: string;
  description: string;
};

export type ErrorStateCommonDescription = {
  commonDescription?: string;
};

export type ErrorStateBarierType = { className?: string } & (ErrorStateFullyCustom | ErrorStateCommonDescription);

const ErrorBarrier = (props: ErrorStateBarierType) => {
  const Crisp = useCrisp();
  const t = useTranslations('components.ErrorBarrier');

  let TextBlock;
  if ('commonDescription' in props) {
    TextBlock = (
      <>
        <h3 className="text-xl font-medium text-primary">{t('title')}</h3>
        {<p className="text-base text-secondary">{t('description', { commonDescription: props.commonDescription })}</p>}
      </>
    );
  } else if ('title' in props && 'description' in props) {
    TextBlock = (
      <>
        <h3 className="text-xl font-medium text-primary">{props.title}</h3>
        {props.description && <p className="text-base text-secondary">{props.description}</p>}
      </>
    );
  }

  return (
    <div className={cn('space-y-4 py-4 text-center', props.className)}>
      <FontAwesomeIcon icon={faTriangleExclamation} className="mx-auto !h-16 !w-auto text-gray-400" />
      {TextBlock}
      <Button buttonColor="blue" onClick={() => Crisp?.chat.open()}>
        {t('talkToSupport')}
      </Button>
    </div>
  );
};

export default ErrorBarrier;
