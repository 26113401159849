'use client';

import { initialiseCrisp } from '@/context/auth/crispUtils';
import { useUser } from '@/context/user';
import useSingleWorkspace from '@/requests/queries/singleWorkspace';
import { Crisp } from 'crisp-sdk-web';
import { useEffect } from 'react';

function useCrispInner() {
  const { user } = useUser();
  const { data: workspace } = useSingleWorkspace();

  useEffect(() => {
    if (Crisp && !Crisp.isCrispInjected()) {
      initialiseCrisp({ user, workspaceName: workspace?.name });
    }
  }, [Crisp]);

  return Crisp;
}

export default function useCrisp() {
  if (typeof window === 'undefined') {
    return null;
  }

  return useCrispInner();
}
