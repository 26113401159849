export const capitalise = (s: unknown) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export type Falsy = false | 0 | '' | null | undefined;

export const isTruthy = <T>(x: T | Falsy): x is T => !!x;

export const ForceBoolean = (value: unknown, strict = true): boolean => {
  switch (typeof value) {
    // Example: true and false
    case 'boolean':
      return value;
    // Example: 0 and 1
    case 'number':
      return value > 0;
    // Example: any object except null will return true
    case 'object':
      return !(value === null);
    // Example: 'true' or 'false' or '0' or '1'
    case 'string':
      if (!strict) {
        if (value === 'false' || value === '0') {
          return false;
        }

        return true;
      }

      return value === 'true' || value === '1';
    // Example: undefined
    case 'undefined':
    default:
      return false;
  }
};

const MAX_STRING_LENGTH = 150;

export const truncate = (text: string) => {
  let output = text;
  if (text && text.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...';
  }
  return output;
};
