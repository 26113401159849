'use client';

import { useContext } from 'react';

import { WorkspaceContext, Context } from './provider';

export function useWorkspace(): Context {
  const context = useContext(WorkspaceContext);

  if (!context) {
    return { isFetching: false, workspace: undefined };
  }

  return context;
}
