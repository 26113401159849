'use client';

import useSingleWorkspace from '@/requests/queries/singleWorkspace';
import { Workspace } from '@/services/workspaces/single';
import { createContext } from 'react';
import { useMemo, useEffect } from 'react';

import { useAnalytics } from '@everfund/event-detective';

export type Context = {
  workspace?: Workspace;
  isFetching: boolean;
};

export const WorkspaceContext = createContext<Context>({ workspace: undefined, isFetching: false });

type ProviderProps = {
  children: React.ReactNode;
  id?: string | null;
};

export function WorkspaceProvider({ children }: ProviderProps) {
  const { group } = useAnalytics();
  const { data: workspace, isFetching } = useSingleWorkspace();

  const contextValue = useMemo(
    () => ({
      workspace: workspace,
      isFetching,
    }),
    [workspace, isFetching]
  );

  useEffect(() => {
    if (workspace?.id) {
      group({
        groupId: workspace.id,
        traits: {
          name: workspace.name,
          plan: workspace.type,
        },
      });
    }
  }, [group, workspace]);

  return <WorkspaceContext.Provider value={contextValue}>{children}</WorkspaceContext.Provider>;
}
