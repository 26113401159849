'use client';

import { useContext } from 'react';

import { OrganizationsContext, Context } from './provider';

export function useOrganizations(): Context {
  const context = useContext(OrganizationsContext);

  if (!context) {
    return { isFetching: false, organizations: undefined };
  }

  return context;
}
