import { Workspace } from '@/services/workspaces/single';
import { useQuery } from '@tanstack/react-query';

export const WORKSPACE_QUERY_KEY = 'workspace';

const useSingleWorkspace = () =>
  useQuery<unknown, unknown, Workspace>({
    queryFn: async () => await (await fetch('/api/workspace')).json(),
    queryKey: [WORKSPACE_QUERY_KEY],
  });

export default useSingleWorkspace;
