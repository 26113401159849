import { EVERFUND_MARKETING_DOMAIN } from '@/context/constants';
import { useTranslations } from 'next-intl';

export const AuthenticationFooter = () => {
  const t = useTranslations('layouts.components.Footer');

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 px-8 text-center font-sans text-slate-700 md:flex-row md:justify-between ">
      <span className="text-secondary">
        <a
          href={`https://${EVERFUND_MARKETING_DOMAIN}`}
          className="text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
        >
          {t('copywrite', { year: new Date().getFullYear() })}
        </a>
      </span>
      <span className="flex flex-row flex-wrap justify-center gap-2">
        <a
          href={`https://www.iubenda.com/privacy-policy/82630393/cookie-policy`}
          className="text-right text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
        >
          {t('cookiePolicy')}
        </a>
        <a
          href={`https://www.iubenda.com/privacy-policy/82630393/full-legal`}
          className="text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
        >
          {t('privacyPolicy')}
        </a>
        <a
          href={`https://www.iubenda.com/terms-and-conditions/82630393`}
          className="text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
        >
          {t('termsAndConditions')}
        </a>
      </span>
    </div>
  );
};
